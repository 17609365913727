<template>
  <section class="h-100">

    <div class="m-auto text-center mb-7">
      <img
        src="@/assets/images/menu_2.svg"
        class="w-full"
      >

      <img
        src="@/assets/images/setup_profile/step_4.svg"
        class="mx-auto mt-5"
      >
      <span class="text-neutral">Step 4 of 10</span>
    </div>

    <div class="flex h-screen text-center" style="height: 80vh;">

      <div class="m-auto pb-10 w-2/3">

        <div class="mt-9">
          <h3 class="text text-center text-black mb-4">
            {{ firstName }}'s Education
          </h3>

          <a href="#" class="text-primary font-bold">Where did you get this information?</a>
        </div>

        <div v-if="!editable">

          <div class="h-56 grid grid-cols-2 justify-center content-center place-items-center">

            <div class="content flex py-2 text-left w-2/3 mb-5" v-for="data in education" :key="data.node_uuid">
              <img
                src="@/assets/images/university_of_oregon.png"
                class="w-12 h-12"
              >
              <div class="item-body px-2 ">
                <span class="text text-black font-bold block">
                  {{ data.school_name }}
                </span>

                <span class="text text-primary block">
                  {{ data.degree }} - {{ data.area_study }}
                </span>

                <span class="text text-black block">
                  {{ getStringDate(data.start_date) }} - {{ getStringDate(data.end_date) }}
                </span>
              </div>
            </div>
          </div>

          <div>
            <router-link
              :to="{name: 'v2.profile.experience'}"
              class="text-primary float-left"
            >
              <img
                src="@/assets/images/icons/ic_chevron_left_blue.svg"
                class="inline"
              >
              Back
            </router-link>

            <a
              class="btn btn-primary-outline btn-large align-self-center me-auto mr-5"
              @click="changeMode()"
            >
              Edit
            </a>

            <router-link
              :to="{name: 'v2.profile.places'}"
              class="btn btn-primary btn-large align-self-center me-auto"
            >
              Looks Good!
            </router-link>

            <router-link
              :to="{name: 'v2.profile.places'}"
              class="text-primary float-right"
            >
              Skip

              <img
                src="@/assets/images/icons/ic_chevron_right_blue.svg"
                class="inline"
              >
            </router-link>
          </div>
        </div>

        <div v-if="editable">
          <form
            class="mt-10 mb-10"
            @submit.prevent="register"
          >

            <div
              v-if="errorMessage"
              class="flex items-center text-dark text-sm mb-5 alert-error"
              role="alert"
            >
              <img
                src="@/assets/images/ic_danger.svg"
                class="mr-2"
              >
              <p>{{ errorMessage }}</p>
            </div>

            <div class="mb-10">
              <div class="form-check w-1/2 inline-block text-right">
                <label class="form-check-label" for="flexCheckIndeterminate">
                  Show
                </label>

                <label class="switch">
                  <input type="checkbox" checked>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>

            <div class="mb-10">
              <input
                v-model="schoolName"
                type="text"
                class="form-control w-1/2"
                placeholder="School Name"
                required
              >
            </div>

            <div class="mb-10">
              <input
                v-model="areaStudy"
                type="text"
                class="form-control"
                placeholder="Area of Study"
                style="width: 23.3%;margin-right: 40px;"
                required
              >

              <input
                v-model="degree"
                type="text"
                class="form-control w-1/4"
                placeholder="Degree"
                style="width: 23.3%;"
                required
              >
            </div>

            <div class="mb-10">
              <input
                v-model="startDate"
                type="text"
                class="form-control"
                placeholder="Start Date"
                style="width: 23.3%;margin-right: 40px;"
                required
              >

              <input
                v-model="endDate"
                type="text"
                class="form-control w-1/4"
                placeholder="End Date"
                style="width: 23.3%;"
                required
              >
            </div>

            <div class="mb-10">
              <div class="form-check w-1/2 inline-block">
                <label class="form-check-label text-neutral float-left" for="flexCheckIndeterminate">
                  I’m currently a student here
                </label>

                <label class="switch float-right">
                  <input type="checkbox" checked>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>

            <div class="mb-6">
              <textarea
                v-model="notableProjects"
                rows="4"
                cols="10"
                class="w-1/2 p-2"
                placeholder="Notable Projects"
              ></textarea>
            </div>

            <div>
              <textarea
                v-model="awards"
                rows="2"
                cols="10"
                class="w-1/2 p-2"
                placeholder="Awards"
              ></textarea>
            </div>
          </form>

          <div class="mb-10">
            <a
              href="#"
              class="text-primary align-self-center me-auto"
            >
              Add Another

              <img
                src="@/assets/images/icons/ic_plus.svg"
                class="inline-block"
              >
            </a>
          </div>

          <div>
            <router-link
              :to="{name: 'v2.profile.experience'}"
              class="text-primary float-left"
            >
              <img
                src="@/assets/images/icons/ic_chevron_left_blue.svg"
                class="inline"
              >
              Back
            </router-link>

            <a
              href="#"
              @click="changeMode()"
              class="btn btn-primary-outline btn-large align-self-center me-auto mr-5"
            >
              Finish Later
            </a>

            <a
              href="#"
              @click="submit()"
              class="btn btn-primary btn-large align-self-center me-auto"
            >
              Onwards
            </a>

            <router-link
              :to="{name: 'v2.profile.places'}"
              class="text-primary float-right"
            >
              Skip

              <img
                src="@/assets/images/icons/ic_chevron_right_blue.svg"
                class="inline"
              >
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Education',
  data() {
    return {
      firstName: '',
      schoolName: '',
      areaStudy: '',
      degree: '',
      startDate: '',
      endDate: '',
      notableProjects: '',
      awards: '',
      errorMessage: null,
      editable: false,
      education: [],
      uuid: null
    };
  },
  methods: {
    changeMode: function() {
      this.editable = !this.editable;
    },
    getStringDate: function(date) {
      return new Date(date).toLocaleString('en-us',{month:'short', year:'numeric'});
    },
    getEducation() {
      axios.get(process.env.VUE_APP_API_URL + '/attributes/education/' + this.uuid, {
        headers: {
          'Authorization': `Bearer`
        }
      })
      .then((response) => {
        this.education = response.data.attributes;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    submit() {
      let data = {
        uuid: this.uuid,
        node_type: 'education',
        attributes: {
          school_name: this.schoolName,
          area_study: this.areaStudy,
          degree: this.degree,
          start_date: this.startDate,
          end_date: this.endDate,
          notable_projects: this.notableProjects,
          awards: this.awards
        }
      };

      axios.post(process.env.VUE_APP_API_URL + '/attribute', data, {
        headers: {
          'Authorization': `Bearer`
        },
      })
      .then((response) => {
        console.log(response);

        this.changeMode();
        this.getEducation();
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  mounted() {
    let firstName = localStorage.getItem('preferredname');
    this.uuid = localStorage.getItem('userUuid');

    if (firstName && firstName !== '') {
      this.firstName = firstName;
    }

    this.getEducation();
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: bold;
}
h3 > strong {
  font-weight: bold;
}
</style>
